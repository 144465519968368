// use vuex modules

//chart display box
const chartRender = {
    state: () => ({
        //show or hide
        isShow: true
    }),
    getters: {

    },
    mutations: {

    },
    actions: {

    }
}

export default chartRender