(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("echarts"), require("Vuex"), require("Vue"));
	else if(typeof define === 'function' && define.amd)
		define(["echarts", "Vuex", "Vue"], factory);
	else if(typeof exports === 'object')
		exports["chartmix"] = factory(require("echarts"), require("Vuex"), require("Vue"));
	else
		root["chartmix"] = factory(root["echarts"], root["Vuex"], root["Vue"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__164e__, __WEBPACK_EXTERNAL_MODULE__5880__, __WEBPACK_EXTERNAL_MODULE__8bbf__) {
return 